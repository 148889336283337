const quizzes = [
        {
           q_id: 'Q1243', title: 'Population of canada', q_no: 8, q_type: 'One By One', mark: 0, status: 'Completeted'
        },
        {
          q_id: 'Q1234', title: 'Population of German', q_no: 12, q_type: 'ALL In One', mark: 22, status: 'Completeted'
        },
        {
          q_id: 'Q1234', title: 'Population of German', q_no: 11, q_type: 'ALL In One', mark: 82, status: 'Running'
        },
        {
            q_id: 'Q1234', title: 'Population of German', q_no: 7, q_type: 'ALL In One', mark: 22, status: 'Coming Up'
        },
        {
            q_id: 'Q1234', title: 'Population of German', q_no: 33, q_type: 'ALL In One', mark: 82, status: 'Coming Up'
        },
        {
            q_id: 'Q1234', title: 'Population of German', q_no: 55, q_type: 'ALL In One', mark: 62, status: 'Coming Up'
        },
        {
            q_id: 'Q1234', title: 'Population of German', q_no: 121, q_type: 'ALL In One', mark: 52, status: 'Coming Up'
        }
]

export default {
    quizzes
}