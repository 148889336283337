<template>
  <div>
    <div class="rounded" style="background-color: #dbe2f5">
      <div class="rounded p-3">
        <h2 class="text-2xl font-extrabold text-primary text-uppercase">
          Quiz Test
        </h2>
        <div class="rounded align-center mt-5 mb-2 flex justify-between">
          <span>
            <p class="font-semibold">Total Quiz <small class="text-sm font-bold text-primary ml-2">{{ getQuizData.total_quiz }}</small></p>
          </span>
          <span>
            <p class="font-semibold">Completed <small class="text-sm font-bold text-black ml-2">{{ getQuizData.completed }}</small></p>
          </span>
          <span>
            <p class="font-semibold">Ramaining <small class="text-sm font-bold text-black ml-2">{{ getQuizData.remaining }}</small></p>
          </span>
          <span class="align-center flex justify-between">
            <p class="font-semibold">Completion Rate</p>
            <md-progress-bar class="rounded mx-2 w-40" md-mode="determinate" :md-value="getQuizData.completion_rate"></md-progress-bar>
            <small class="text-sm font-bold text-black" style="margin-top: -4px;">{{ getQuizData.completion_rate }}%</small>
          </span>
          <span class="align-center flex justify-between">
            <p class="font-semibold">Overall Score</p>
            <md-progress-bar class="rounded mx-2 w-40" md-mode="determinate" :md-value="getQuizData.everall_score"></md-progress-bar>
            <small class="text-sm font-bold text-black" style="margin-top: -4px;">{{ getQuizData.everall_score }}%</small>
          </span>
        </div>
      </div>

    </div>
    <div class="panel mt-6 p-3">
        <div class="pb-2 panel-body p-3">
          <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
            <SelectComponent
              :items="this.item_quizes"
              :placeholder="false"
              return-type="object"
              @getObject="getObject"
              class="w-64 m-1" />
            <SearchBox
              placeholder="Search"
              class="w-74 p-2 m-1"
              v-model="keyword"
              result-key="branchName"
              />
          </div>
        </div>
        <Table :items="getQuizs" @onSelect="onSelect" hover-action >
          <template
            slot="row"
            slot-scope="{ item }"
            md-selectable="single"
          >
            <md-table-cell md-sort-by="id" md-label="Q_ID" class="font-bold">{{ item.q_s_id }}</md-table-cell>
            <md-table-cell md-sort-by="title" md-label="QUESTION TITLE" class="font-bold">{{ item.title }}</md-table-cell>
            <md-table-cell md-label="NO OF QUESTION" class="pl-4 font-bold" >{{ item.question_count}}</md-table-cell>
            <md-table-cell md-label="QUIZ TYPE" class="font-bold">{{ item.template_type }}</md-table-cell>
            <md-table-cell md-label="MARKS" class="pl-4 font-bold">
              {{ item.total_marks === 0 ? 'N/A' : item.total_marks }}
            </md-table-cell>
            <md-table-cell md-label="MY MARK" class="pl-4 font-bold">
              {{ item.my_marks}}
            </md-table-cell>
            <md-table-cell md-label="STATUS">
              
             <span 
             class="font-bold" 
             :class="item.status !== 1 ? 'text-blue' : 'text-success' ">
              {{item.status !== 1 ? 'Not Started' : 'completed'}} 
            </span>
            
            </md-table-cell>
            <md-table-cell>
              <span class="action flex">
                <md-icon class="bg-success text-white rounded-full p-2 text-sm">send</md-icon>
              </span>
            </md-table-cell>
          </template>
        </Table>
        <div class="flex justify-between align-center my-6">
            <md-button class="bg-victoria text-white text-italic rounded ml-0">Back</md-button>
            <Paginate
              :start="meta.current_page"
              :end="meta.last_page"
              :total="meta.last_page"
              :limit="meta.per_page"
              @on-start="onStart"
              @on-end="onEnd"
            />
        </div>
        <Dialog>
          <component :is="component" :Quiz_item="this.Quiz_item"></component>
        </Dialog>
    </div>
  </div>
</template>

<script>
import { SearchBox, SelectComponent ,Table, Paginate, Dialog } from "@/components";
import QuizAlert from "@/views/student/quiz-test/Alert.vue";
import data from "@/data/quiz-test/data";
import { mapMutations, mapGetters, mapActions } from "vuex";
import { query } from "@/utils/queryString";

export default {
  data() {
    return {
      quizzes: data.quizzes,
      amount: 10,
      Quiz_item: null,
      keyword: '',
      component: 'QuizAlert',
      item_quizes: [
        { id: 1, name: "ALL QUIZES" },
        { id: 2, name: "Item 02" },
        { id: 3, name: "Item 03" },
        { id: 4, name: "Item 04" }
      ]
    };
  },
  components: {
    Paginate,
    Table,
    SearchBox,
    SelectComponent,
    Dialog,
    QuizAlert,
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actQuizSetList(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actQuizSetList(`?${query.get()}`);
        }
    }
  },
  computed: {
    ...mapGetters({
      getQuizs: "quiz/getQuizs",
      getQuizSetId: "quiz/getQuizSetId",
      getQuizData: "quiz/getQuizData",
      meta: "quiz/getQuizListMeta"
    })
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actQuizSetList: "quiz/actQuizSetList",
      actShowQuizs: "quiz/actShowQuizs",
      actGetQuizSetId: "quiz/actGetQuizSetId",
    }),
    submitSearchKeyword() {
      this.actQuizSetList(`?${query.get('search', this.keyword)}`);
    },
    onSelect(item) {
      console.log(item)
      this.component = 'QuizAlert';

      this.dialog(true);
      this.Quiz_item = item;
    },
    onStart(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuizSetList(`?${query.get()}`);
    },
    onEnd(value) {
      this.selectedValue = value;
      if(this.selectedValue == '') {
        query.remove('page');
      }else {
        query.set('page', this.selectedValue);
      }
      this.actQuizSetList(`?${query.get()}`);
    },
    searchResults(){

    }
  },
  mounted(){
     this.dialog(false);
  },
  created() {
    this.actQuizSetList(``); // pass emtpy query value for getting all data
  },
};
</script>

<style></style>
