<template>
    <div id="dataLoader" class="flex align-center justify-center  md-layout-column md-sl__br">
        <div class="">
            <div class="loader pb-2"></div> 
            <span class="pt-2">
              Please wait while loading...
            </span>
          </div>
        </div>
</template>
<script>
export default {
    name: 'DialogCustom',
    data() {
      return {
         loading: false
      }
    }
}
</script>

<style scoped>
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid rebeccapurple;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
#dataLoader{
    z-index: 99999999999;
    position: fixed;
    background: rgb(255 255 255 / 89%);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.displayText{
 text-align: center;
 color: red;
}
</style>